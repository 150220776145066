import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Gamepage from './pages/Gamepage';
import UserDataForm from './pages/UserDataForm';
import ThankYouPage from './pages/ThankYouPage';

const App = () => {
  

  

  // Call checkWin whenever baskets or items change
  // useEffect(() => {
  //   checkWin();
  // }, [baskets, items]);

  return (
    
      
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/game" element={<Gamepage />} />
          <Route path="/users" element={<UserDataForm />} />
          <Route path="/thank" element={<ThankYouPage />} />

          
            
          
        </Routes>
      
  );
};

export default App;
