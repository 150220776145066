import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/saveUserData';

const UserDataForm = () => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [nameError, setNameError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setNameError('');
    setMobileError('');

    let isValid = true;

    // Name validation
    if (!name.trim()) {
      setNameError('Name is required');
      isValid = false;
    } else if (name.trim().length < 3) {
      setNameError('Name must be at least 3 characters');
      isValid = false;
    }

    // Mobile number validation
    if (!mobile.trim()) {
      setMobileError('Mobile number is required');
      isValid = false;
    } else if (!/^\d{10}$/.test(mobile.trim())) {
      setMobileError('Mobile number must be exactly 10 digits');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/saveUserData`, {
        name,
        mobile,
      });

      console.log('Data sent:', response.data);

      // Show SweetAlert for successful submission
      Swal.fire({
        title: 'Thank You!',
        text: 'Your information has been submitted successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        // Navigate to thank you page
        navigate('/');
      });
    } catch (error) {
      console.error('Error sending data:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to submit your information. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div style={{ backgroundColor: '#6ab2d0' }} className="min-h-screen flex items-center justify-center bg-blue-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Enter Your Information</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700">Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full mt-2 p-2 border border-gray-300 rounded"
              placeholder="Enter your name"
            />
            {nameError && <p className="text-red-500 text-xs mt-1">{nameError}</p>}
          </div>
          <div>
            <label className="block text-gray-700">Mobile Number:</label>
            <input
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="w-full mt-2 p-2 border border-gray-300 rounded"
              placeholder="Enter your mobile number"
            />
            {mobileError && <p className="text-red-500 text-xs mt-1">{mobileError}</p>}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserDataForm;
  