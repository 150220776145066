import React from 'react';

const ThankYouPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-blue-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Thank You!</h2>
        <p className="text-center">Your information has been submitted successfully.</p>
        {/* Add additional content or customization as needed */}
      </div>
    </div>
  );
};

export default ThankYouPage;
