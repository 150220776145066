import React from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import logo from '../image/match&win-logo.png';
import './Homepage.css'; // Import your CSS file for animations if needed

const Homepage = () => {
    const navigate = useNavigate();
    const startGame = () => {
        navigate('/game');
    };

    return (
        <div style={{ backgroundColor: '#6ab2d0' }} className="flex flex-col items-center justify-center h-screen">
            <img src={logo} alt="Logo" className="h-52 w-auto mr-3 mb-14" />
            <CSSTransition in={true} timeout={500} classNames="fade">
                <button style={{ backgroundColor: '#005da9' }} className="px-6 py-1 text-white rounded-lg text-2xl" onClick={startGame}>
                    Start
                </button>
            </CSSTransition>
        </div>
    );
}

export default Homepage;
