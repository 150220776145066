import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import solo from '../solo.png'; 
import digital from '../digital.png';
import img1 from '../image/01.png';
import img2 from '../image/02.png';
import img3 from '../image/03.png';
import img4 from '../image/04.png';
import img5 from '../image/05.png';
import img6 from '../image/06.png';
import img7 from '../image/07.png';
import img8 from '../image/08.png';
import img9 from '../image/09.png';
import img10 from '../image/10.png';
import logo from '../image/match&win-logo.png';
import Swal from 'sweetalert2';
import './Gamepage.css';

const Gamepage = () => {
    const navigate = useNavigate();

    const [items, setItems] = useState([
        { id: 'item1', name: 'Item 1', image: img1 },
        { id: 'item2', name: 'Item 2', image: img2 },
        { id: 'item3', name: 'Item 3', image: img3 },
        { id: 'item4', name: 'Item 4', image: img4 },
        { id: 'item5', name: 'Item 5', image: img5 },
        { id: 'item6', name: 'Item 6', image: img6 },
        { id: 'item7', name: 'Item 7', image: img7 },
        { id: 'item8', name: 'Item 8', image: img8 },
        { id: 'item9', name: 'Item 9', image: img9 },
        { id: 'item10', name: 'Item 10', image: img10 },
    ]);

    const [baskets, setBaskets] = useState({
        solo: [],
        db: [],
    });

    const [timeLeft, setTimeLeft] = useState(30);
    const [intervalId, setIntervalId] = useState(null);
    const [score, setScore] = useState(0);

    const [draggedItem, setDraggedItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleDragStart = (event, item, origin) => {
        if (event.dataTransfer) {
            event.dataTransfer.setData('text/plain', JSON.stringify(item));
            event.dataTransfer.setData('origin', origin);
            setDraggedItem({ item, origin });
        }
    };

    const stopTimer = () => {
        clearInterval(intervalId);
    };
    

    const handleDrop = (event, basket) => {
        event.preventDefault();
        if (event.dataTransfer) {
            const item = JSON.parse(event.dataTransfer.getData('text/plain'));
            const origin = event.dataTransfer.getData('origin');

            setBaskets((prevState) => ({
                ...prevState,
                [basket]: [...prevState[basket], item],
            }));

            if (origin === 'items') {
                setItems((prevItems) => prevItems.filter((i) => i.id !== item.id));
            } else if (origin !== basket) {
                const otherBasket = basket === 'solo' ? 'db' : 'solo';
                setBaskets((prevState) => ({
                    ...prevState,
                    [otherBasket]: prevState[otherBasket].filter((i) => i.id !== item.id),
                }));
            }

            checkWin(); // Check win condition after every drop
        }
        setSelectedItem(null);
    };

    const checkWin = () => {
        const correctItemsInSolo = ['item2', 'item4', 'item6', 'item8', 'item10'];
        const correctItemsInDB = ['item1', 'item3', 'item5', 'item7', 'item9'];
    
        const soloItems = baskets.solo.map((item) => item.id);
        const dbItems = baskets.db.map((item) => item.id);
    
        let score1 = 0;
    
        // Calculate score for solo items
        correctItemsInSolo.forEach((item) => {
            if (soloItems.includes(item)) {
                score1 += 10;
            }
        });
    
        // Calculate score for db items
        correctItemsInDB.forEach((item) => {
            if (dbItems.includes(item)) {
                score1 += 10;
            }
        });
    
        setScore(score1); // Update the score state
    
        // Apply animation when score is increased
        const body = document.body;
        if (score1 % 10 === 0 && score1 !== 0) {
            body.classList.add('score-anim');
            setTimeout(() => {
                body.classList.remove('score-anim');
            }, 1000); // Duration should match the CSS animation duration
        }
    
        const soloCorrect = correctItemsInSolo.every((item) => soloItems.includes(item));
        const dbCorrect = correctItemsInDB.every((item) => dbItems.includes(item));
    
        if (baskets.solo.length + baskets.db.length === 10) {
            stopTimer(); // Stop the timer when all items are placed
            if (score1 >= 80) { // Check if score is 80 or above for win
                Swal.fire({
                    title: 'You win!',
                    text: `Congratulations! Your score is ${score1}.`,
                    icon: 'success',
                    confirmButtonText: 'Next',
                    customClass: {
                        confirmButton: 'swal-button-class'
                    },
                    buttonsStyling: false,
                }).then(() => navigate('/users'));
            } else {
                Swal.fire({
                    title: 'You lose!',
                    text: `Your score is ${score1}. Try again!`,
                    icon: 'error',
                    confirmButtonText: 'Next',
                    customClass: {
                        confirmButton: 'swal-button-class'
                    },
                    buttonsStyling: false,
                }).then(() => navigate('/users'));
            }
        }
    };
    
    

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    const handleDropTouch = (basket) => {
        if (draggedItem) {
            const { item, origin } = draggedItem;

            setBaskets((prevState) => ({
                ...prevState,
                [basket]: [...prevState[basket], item],
            }));

            if (origin === 'items') {
                setItems((prevItems) => prevItems.filter((i) => i.id !== item.id));
            } else if (origin !== basket) {
                const otherBasket = basket === 'solo' ? 'db' : 'solo';
                setBaskets((prevState) => ({
                    ...prevState,
                    [otherBasket]: prevState[otherBasket].filter((i) => i.id !== item.id),
                }));
            }

            checkWin(); // Check win condition after every drop
        }
        setSelectedItem(null);
    };

    const handleTouchEnd = (event) => {
        event.preventDefault();
        const touch = event.changedTouches[0];
        const target = document.elementFromPoint(touch.clientX, touch.clientY);
        if (target && (target.id === 'solo-basket' || target.id === 'db-basket')) {
            const basket = target.id.replace('-basket', '');
            handleDropTouch(basket);
        }
        handleDragEnd();
    };

    const handleTouchStart = (event, item, origin) => {
        setDraggedItem({ item, origin });
        setSelectedItem(item.id);
        
        event.preventDefault();
    };

    useEffect(() => {
        const id = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime === 0) {
                    clearInterval(id);
                    Swal.fire({
                        title: "Time's up!",
                        text: `Your score is ${score}.`,
                        icon: score >= 80 ? 'success' : 'error',
                        confirmButtonText: "Next",
                        customClass: {
                            confirmButton: 'swal-button-class'
                        },
                        buttonsStyling: false,
                    }).then(() => navigate('/users'));
                    return prevTime;
                }
                return prevTime - 1;
            });
        }, 1000);
        setIntervalId(id);

        return () => clearInterval(id);
    }, [navigate, score]);

    useEffect(() => {
        checkWin();
    }, [baskets]);

    return (
        <div 
            className="min-h-screen flex flex-col items-center p-6" 
            style={{ backgroundColor: '#6ab2d0' }}
        >
            <img src={logo} alt="Logo" className="h-28 w-auto mr-3" />
            <div className="text-2xl mb-6 text-red-600">Time Left: <span id="time">{timeLeft}</span> seconds</div>
            <div style={{ color: '#faea0c' }} className="text-4xl mb-6 font-bold">Score: <span id="score">{score}</span></div>
            <div className="flex justify-center items-start space-x-16 w-full">
                <div className="flex flex-col items-center space-y-8">
                    <div
                        className="basket flex-1 w-full max-w-md border-4 border-dashed border-gray-700 flex flex-col items-center justify-center bg-white shadow-lg p-4 pb-28"
                        id="solo-basket"
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, 'solo')}
                        onTouchEnd={handleTouchEnd}
                    >
                        <img src={solo} alt="Solo Logo" className="mb-2 w-50 h-auto" />
                        <div className="flex flex-col items-center">
                            {baskets.solo.map((item) => (
                                <div
                                    key={item.id}
                                    className={`item m-1 ${selectedItem === item.id ? '' : ''}`}
                                    draggable
                                    onDragStart={(event) => handleDragStart(event, item, 'solo')}
                                    onTouchStart={(event) => handleTouchStart(event, item, 'solo')}
                                    onTouchMove={(event) => event.preventDefault()}
                                >
                                    <img src={item.image} alt={item.name} className="item-image" style={{ width: '300px', height: 'auto' }} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className="basket flex-1 w-full max-w-md border-4 border-dashed border-gray-700 flex flex-col items-center justify-center bg-white shadow-lg p-4 pb-28"
                        id="db-basket"
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, 'db')}
                        onTouchEnd={handleTouchEnd}
                    >
                        <img src={digital} alt="DB Logo" className="mb-2 w-50 h-auto" />
                        <div className="flex flex-col items-center">
                            {baskets.db.map((item) => (
                                <div
                                    key={item.id}
                                    className={`item m-1 ${selectedItem === item.id ? '' : ''}`}
                                    draggable
                                    onDragStart={(event) => handleDragStart(event, item, 'db')}
                                    onTouchStart={(event) => handleTouchStart(event, item, 'db')}
                                    onTouchMove={(event) => event.preventDefault()}
                                >
                                    <img src={item.image} alt={item.name} className="item-image" style={{ width: '300px', height: 'auto' }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full max-w-3xl">
                    {items.map((item) => (
                        <div
                            key={item.id}
                            className={`item cursor-pointer m-2 ${selectedItem === item.id ? ' border-4 border-white rounded-full'  : ''}`}
                            draggable
                            onDragStart={(event) => handleDragStart(event, item, 'items')}
                            onTouchStart={(event) => handleTouchStart(event, item, 'items')}
                            onTouchMove={(event) => event.preventDefault()}
                        >
                            <img src={item.image} alt={item.name} className="item-image" style={{ width: '300px', height: 'auto' }} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Gamepage;
